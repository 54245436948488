const LegacyModalOrnament = ({ width = '329', height = '28' }: { width?: string; height?: string }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 329 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_25214_66713)">
                <path
                    d="M328.965 12.3594C219.374 16.6553 109.759 16.9404 0.117188 13.2148"
                    stroke="#837163"
                    stroke-width="1.90086"
                    stroke-miterlimit="10"
                />
                <path d="M306.289 13.2344L315.546 21.5792" stroke="#837163" stroke-width="1.90086" stroke-miterlimit="10" />
                <path d="M296.383 13.2341L306.191 2.57031" stroke="#837163" stroke-width="1.90086" stroke-miterlimit="10" />
                <path d="M279.172 14.0781L288.961 24.4568" stroke="#837163" stroke-width="1.90086" stroke-miterlimit="10" />
                <path
                    d="M262.781 14.2031C265.851 11.9856 268.601 9.35683 270.955 6.39062"
                    stroke="#837163"
                    stroke-width="1.90086"
                    stroke-miterlimit="10"
                />
                <path
                    d="M250.961 14.7344C252.679 17.6488 254.584 20.4489 256.664 23.1172"
                    stroke="#837163"
                    stroke-width="1.90086"
                    stroke-miterlimit="10"
                />
                <path d="M234.297 14.842L241.9 7.96094" stroke="#837163" stroke-width="1.90086" stroke-miterlimit="10" />
                <path d="M220.75 15.2266L227.783 23.6664" stroke="#837163" stroke-width="1.90086" stroke-miterlimit="10" />
                <path
                    d="M209.586 15.4196C212.195 12.5655 215.072 9.96845 218.178 7.66406"
                    stroke="#837163"
                    stroke-width="1.90086"
                    stroke-miterlimit="10"
                />
                <path
                    d="M202.008 16.0234C203.797 18.7615 205.799 21.3546 207.995 23.7789"
                    stroke="#837163"
                    stroke-width="1.90086"
                    stroke-miterlimit="10"
                />
                <path d="M185.062 15.5562L191.354 9.35938" stroke="#837163" stroke-width="1.90086" stroke-miterlimit="10" />
                <path
                    d="M170.453 15.7422C172.354 17.9852 174.787 20.9505 176.593 23.1745"
                    stroke="#837163"
                    stroke-width="1.90086"
                    stroke-miterlimit="10"
                />
                <path
                    d="M157.492 15.8381C160.429 13.9771 162.958 11.5391 164.925 8.67188"
                    stroke="#837163"
                    stroke-width="1.90086"
                    stroke-miterlimit="10"
                />
                <path
                    d="M144.242 15.4922C145.855 17.8918 147.715 20.1154 149.793 22.1262"
                    stroke="#837163"
                    stroke-width="1.90086"
                    stroke-miterlimit="10"
                />
                <path
                    d="M130.539 15.7451C132.663 13.7424 134.408 11.3727 135.69 8.75"
                    stroke="#837163"
                    stroke-width="1.90086"
                    stroke-miterlimit="10"
                />
                <path
                    d="M114.82 15.7422C115.875 18.218 117.291 20.524 119.021 22.5853"
                    stroke="#837163"
                    stroke-width="1.90086"
                    stroke-miterlimit="10"
                />
                <path
                    d="M100.492 15.4903C103.303 13.6985 105.927 11.6289 108.324 9.3125"
                    stroke="#837163"
                    stroke-width="1.90086"
                    stroke-miterlimit="10"
                />
                <path
                    d="M87.3516 15.7422C88.8342 17.7761 90.3359 19.791 91.8376 21.8249"
                    stroke="#837163"
                    stroke-width="1.90086"
                    stroke-miterlimit="10"
                />
                <path
                    d="M75.2734 15.2241C77.276 13.3543 79.1794 11.381 80.976 9.3125"
                    stroke="#837163"
                    stroke-width="1.90086"
                    stroke-miterlimit="10"
                />
                <path d="M63.0703 14.4531L68.4688 21.5243" stroke="#837163" stroke-width="1.90086" stroke-miterlimit="10" />
                <path d="M51.8594 14.5487L58.8545 9.35938" stroke="#837163" stroke-width="1.90086" stroke-miterlimit="10" />
                <path d="M41.7422 14.4062L44.6885 21.5535" stroke="#837163" stroke-width="1.90086" stroke-miterlimit="10" />
                <path d="M28.1719 14.2232L34.8819 9.41406" stroke="#837163" stroke-width="1.90086" stroke-miterlimit="10" />
                <path
                    d="M15.0156 13.5156C16.374 15.9191 17.9794 18.1743 19.8058 20.2446"
                    stroke="#837163"
                    stroke-width="1.90086"
                    stroke-miterlimit="10"
                />
                <path
                    d="M5.16406 13.3197C7.30052 11.5987 9.27897 9.69031 11.0758 7.61719"
                    stroke="#837163"
                    stroke-width="1.90086"
                    stroke-miterlimit="10"
                />
                <path
                    d="M308.749 2.56615C308.749 3.07369 308.599 3.56983 308.317 3.99184C308.035 4.41384 307.634 4.74274 307.165 4.93697C306.696 5.1312 306.18 5.18201 305.683 5.08299C305.185 4.98398 304.728 4.73959 304.369 4.3807C304.01 4.02182 303.766 3.56457 303.667 3.06679C303.567 2.569 303.618 2.05303 303.813 1.58412C304.007 1.11522 304.336 0.714448 304.758 0.432475C305.18 0.150503 305.676 0 306.183 0C306.862 0.00497492 307.512 0.276938 307.992 0.757111C308.473 1.23728 308.744 1.8871 308.749 2.56615Z"
                    fill="#837163"
                />
                <path
                    d="M317.571 21.554C317.466 22.0351 317.22 22.4741 316.865 22.8156C316.51 23.157 316.062 23.3857 315.578 23.4725C315.093 23.5593 314.593 23.5005 314.142 23.3034C313.691 23.1063 313.308 22.7798 313.042 22.3652C312.776 21.9506 312.64 21.4665 312.649 20.9742C312.658 20.4818 312.813 20.0033 313.095 19.599C313.376 19.1948 313.771 18.8831 314.229 18.7032C314.688 18.5234 315.189 18.4835 315.67 18.5887C315.991 18.6562 316.295 18.7868 316.565 18.973C316.835 19.1592 317.065 19.3973 317.242 19.6732C317.419 19.9492 317.539 20.2576 317.595 20.5805C317.652 20.9034 317.644 21.2343 317.571 21.554Z"
                    fill="#837163"
                />
                <path
                    d="M291.539 24.4724C291.539 24.9799 291.388 25.4761 291.106 25.8981C290.824 26.3201 290.423 26.649 289.954 26.8432C289.486 27.0374 288.97 27.0883 288.472 26.9892C287.974 26.8902 287.517 26.6458 287.158 26.287C286.799 25.9281 286.555 25.4708 286.456 24.973C286.357 24.4753 286.407 23.9593 286.602 23.4904C286.796 23.0215 287.125 22.6207 287.547 22.3387C287.969 22.0567 288.465 21.9062 288.972 21.9062C289.651 21.9112 290.301 22.1832 290.781 22.6634C291.262 23.1435 291.534 23.7933 291.539 24.4724Z"
                    fill="#837163"
                />
                <path
                    d="M244.39 7.97249C244.39 8.48002 244.24 8.97616 243.958 9.39816C243.676 9.82016 243.275 10.1491 242.806 10.3433C242.337 10.5375 241.821 10.5883 241.323 10.4893C240.826 10.3903 240.368 10.1459 240.009 9.78702C239.651 9.42814 239.406 8.97089 239.307 8.47311C239.208 7.97532 239.259 7.45936 239.453 6.99046C239.647 6.52156 239.976 6.12077 240.398 5.8388C240.82 5.55682 241.316 5.40632 241.824 5.40632C242.162 5.40379 242.496 5.46844 242.809 5.59651C243.121 5.72457 243.405 5.9135 243.644 6.15229C243.883 6.39109 244.072 6.67499 244.2 6.98747C244.328 7.29995 244.393 7.63479 244.39 7.97249Z"
                    fill="#837163"
                />
                <path
                    d="M273.507 6.38646C273.507 6.894 273.357 7.39013 273.075 7.81213C272.793 8.23414 272.392 8.56306 271.923 8.75728C271.454 8.95151 270.938 9.00232 270.441 8.90331C269.943 8.80429 269.486 8.55988 269.127 8.201C268.768 7.84212 268.523 7.38487 268.424 6.88709C268.325 6.3893 268.376 5.87334 268.57 5.40444C268.765 4.93553 269.093 4.53476 269.515 4.25279C269.937 3.97082 270.434 3.82031 270.941 3.82031C271.62 3.82529 272.27 4.09724 272.75 4.57741C273.23 5.05758 273.502 5.70741 273.507 6.38646Z"
                    fill="#837163"
                />
                <path
                    d="M259.226 23.1521C259.226 23.6597 259.076 24.1558 258.794 24.5778C258.512 24.9998 258.111 25.3287 257.642 25.523C257.173 25.7172 256.657 25.768 256.159 25.669C255.661 25.57 255.204 25.3256 254.845 24.9667C254.486 24.6078 254.242 24.1506 254.143 23.6528C254.044 23.155 254.095 22.639 254.289 22.1701C254.483 21.7012 254.812 21.3004 255.234 21.0185C255.656 20.7365 256.152 20.586 256.66 20.586C256.998 20.5835 257.332 20.6481 257.645 20.7762C257.957 20.9043 258.241 21.0932 258.48 21.332C258.719 21.5708 258.908 21.8547 259.036 22.1671C259.164 22.4796 259.229 22.8144 259.226 23.1521Z"
                    fill="#837163"
                />
                <path
                    d="M230.031 23.6677C230.031 24.1752 229.88 24.6714 229.598 25.0934C229.316 25.5154 228.916 25.8443 228.447 26.0385C227.978 26.2327 227.462 26.2836 226.964 26.1846C226.466 26.0855 226.009 25.8411 225.65 25.4822C225.291 25.1234 225.047 24.6661 224.948 24.1683C224.849 23.6705 224.9 23.1546 225.094 22.6857C225.288 22.2168 225.617 21.816 226.039 21.534C226.461 21.2521 226.957 21.1016 227.465 21.1016C228.145 21.1016 228.798 21.3719 229.279 21.8532C229.76 22.3344 230.031 22.9871 230.031 23.6677Z"
                    fill="#837163"
                />
                <path
                    d="M220.75 7.44115C220.75 7.94869 220.599 8.44483 220.317 8.86684C220.035 9.28884 219.634 9.61774 219.165 9.81197C218.696 10.0062 218.181 10.057 217.683 9.95801C217.185 9.85899 216.728 9.61459 216.369 9.2557C216.01 8.89682 215.766 8.43957 215.666 7.94179C215.567 7.444 215.618 6.92804 215.813 6.45914C216.007 5.99023 216.336 5.58945 216.758 5.30748C217.18 5.0255 217.676 4.875 218.183 4.875C218.862 4.87997 219.512 5.15194 219.992 5.63211C220.473 6.11228 220.745 6.7621 220.75 7.44115Z"
                    fill="#837163"
                />
                <path
                    d="M210.562 23.6677C210.562 24.1752 210.411 24.6714 210.13 25.0934C209.848 25.5154 209.447 25.8443 208.978 26.0385C208.509 26.2327 207.993 26.2836 207.495 26.1846C206.997 26.0855 206.54 25.8411 206.181 25.4822C205.822 25.1234 205.578 24.6661 205.479 24.1683C205.38 23.6705 205.431 23.1546 205.625 22.6857C205.819 22.2168 206.148 21.816 206.57 21.534C206.992 21.2521 207.488 21.1016 207.996 21.1016C208.676 21.1016 209.329 21.3719 209.81 21.8532C210.292 22.3344 210.562 22.9871 210.562 23.6677Z"
                    fill="#837163"
                />
                <path
                    d="M194.562 8.66771C194.562 9.17525 194.411 9.6714 194.13 10.0934C193.848 10.5154 193.447 10.8443 192.978 11.0385C192.509 11.2328 191.993 11.2836 191.495 11.1846C190.997 11.0855 190.54 10.8411 190.181 10.4823C189.822 10.1234 189.578 9.66614 189.479 9.16835C189.38 8.67057 189.431 8.15459 189.625 7.68569C189.819 7.21678 190.148 6.81601 190.57 6.53404C190.992 6.25207 191.488 6.10156 191.996 6.10156C192.333 6.10156 192.667 6.16793 192.978 6.29689C193.289 6.42586 193.572 6.61489 193.81 6.85318C194.049 7.09147 194.238 7.37435 194.367 7.68569C194.496 7.99703 194.562 8.33072 194.562 8.66771Z"
                    fill="#837163"
                />
                <path
                    d="M179.523 23.6678C179.523 24.1753 179.372 24.6714 179.09 25.0934C178.809 25.5154 178.408 25.8444 177.939 26.0386C177.47 26.2328 176.954 26.2836 176.456 26.1846C175.958 26.0856 175.501 25.8412 175.142 25.4823C174.783 25.1234 174.539 24.6662 174.44 24.1684C174.341 23.6706 174.392 23.1547 174.586 22.6858C174.78 22.2169 175.109 21.8161 175.531 21.5341C175.953 21.2521 176.449 21.1016 176.957 21.1016C177.294 21.0991 177.629 21.1638 177.942 21.2918C178.254 21.4199 178.538 21.6088 178.777 21.8476C179.016 22.0864 179.205 22.3703 179.333 22.6828C179.461 22.9953 179.525 23.3301 179.523 23.6678Z"
                    fill="#837163"
                />
                <path
                    d="M167.523 8.9646C167.523 9.47214 167.372 9.96827 167.09 10.3903C166.809 10.8123 166.408 11.1412 165.939 11.3354C165.47 11.5296 164.954 11.5805 164.456 11.4814C163.958 11.3824 163.501 11.138 163.142 10.7791C162.783 10.4203 162.539 9.96301 162.44 9.46523C162.341 8.96744 162.392 8.45148 162.586 7.98258C162.78 7.51367 163.109 7.11289 163.531 6.83091C163.953 6.54894 164.449 6.39844 164.957 6.39844C165.637 6.39844 166.29 6.6688 166.771 7.15005C167.253 7.6313 167.523 8.28402 167.523 8.9646Z"
                    fill="#837163"
                />
                <path
                    d="M152.312 21.8396C152.312 22.3471 152.162 22.8433 151.88 23.2653C151.598 23.6873 151.197 24.0162 150.728 24.2104C150.259 24.4046 149.743 24.4554 149.245 24.3564C148.747 24.2574 148.29 24.013 147.931 23.6541C147.572 23.2953 147.328 22.838 147.229 22.3402C147.13 21.8424 147.181 21.3265 147.375 20.8576C147.569 20.3887 147.898 19.9879 148.32 19.7059C148.742 19.4239 149.238 19.2734 149.746 19.2734C150.426 19.2734 151.079 19.5438 151.56 20.025C152.042 20.5063 152.312 21.159 152.312 21.8396Z"
                    fill="#837163"
                />
                <path
                    d="M138.25 8.66771C138.25 9.17525 138.099 9.6714 137.817 10.0934C137.535 10.5154 137.134 10.8443 136.665 11.0385C136.196 11.2328 135.681 11.2836 135.183 11.1846C134.685 11.0855 134.228 10.8411 133.869 10.4823C133.51 10.1234 133.266 9.66614 133.166 9.16835C133.067 8.67057 133.118 8.15459 133.313 7.68569C133.507 7.21678 133.836 6.81601 134.258 6.53404C134.68 6.25207 135.176 6.10156 135.683 6.10156C136.364 6.10156 137.017 6.37193 137.498 6.85318C137.979 7.33442 138.25 7.98713 138.25 8.66771Z"
                    fill="#837163"
                />
                <path
                    d="M121.218 22.1287C121.218 22.6362 121.068 23.1323 120.786 23.5543C120.504 23.9763 120.103 24.3053 119.634 24.4995C119.165 24.6937 118.649 24.7445 118.151 24.6455C117.654 24.5465 117.196 24.3021 116.838 23.9432C116.479 23.5843 116.234 23.1271 116.135 22.6293C116.036 22.1315 116.087 21.6156 116.281 21.1467C116.475 20.6777 116.804 20.277 117.226 19.995C117.648 19.713 118.145 19.5625 118.652 19.5625C119.333 19.5625 119.985 19.8329 120.467 20.3141C120.948 20.7954 121.218 21.4481 121.218 22.1287Z"
                    fill="#837163"
                />
                <path
                    d="M110.898 9.30835C110.898 9.81589 110.747 10.312 110.465 10.734C110.183 11.156 109.783 11.4849 109.314 11.6792C108.845 11.8734 108.329 11.9242 107.831 11.8252C107.333 11.7262 106.876 11.4818 106.517 11.1229C106.158 10.764 105.914 10.3068 105.815 9.80898C105.716 9.31119 105.767 8.79523 105.961 8.32633C106.155 7.85742 106.484 7.45664 106.906 7.17466C107.328 6.89269 107.824 6.74219 108.332 6.74219C108.669 6.74219 109.002 6.80857 109.314 6.93753C109.625 7.0665 109.908 7.25551 110.146 7.4938C110.385 7.73209 110.574 8.01498 110.703 8.32633C110.832 8.63767 110.898 8.97136 110.898 9.30835Z"
                    fill="#837163"
                />
                <path
                    d="M94.3979 22.1287C94.3979 22.6362 94.2475 23.1323 93.9655 23.5543C93.6835 23.9763 93.2827 24.3053 92.8138 24.4995C92.3449 24.6937 91.8289 24.7445 91.3312 24.6455C90.8334 24.5465 90.3761 24.3021 90.0172 23.9432C89.6584 23.5843 89.4139 23.1271 89.3149 22.6293C89.2159 22.1315 89.2668 21.6156 89.461 21.1467C89.6552 20.6777 89.9841 20.277 90.4061 19.995C90.8281 19.713 91.3242 19.5625 91.8318 19.5625C92.5124 19.5625 93.1651 19.8329 93.6463 20.3141C94.1276 20.7954 94.3979 21.4481 94.3979 22.1287Z"
                    fill="#837163"
                />
                <path
                    d="M83.4683 8.66771C83.4683 9.17525 83.3178 9.6714 83.0358 10.0934C82.7538 10.5154 82.353 10.8443 81.8841 11.0385C81.4152 11.2328 80.8993 11.2836 80.4015 11.1846C79.9037 11.0855 79.4464 10.8411 79.0876 10.4823C78.7287 10.1234 78.4843 9.66614 78.3852 9.16835C78.2862 8.67057 78.3371 8.15459 78.5313 7.68569C78.7255 7.21678 79.0545 6.81601 79.4765 6.53404C79.8985 6.25207 80.3946 6.10156 80.9021 6.10156C81.5811 6.10654 82.231 6.3785 82.7112 6.85867C83.1914 7.33885 83.4633 7.98866 83.4683 8.66771Z"
                    fill="#837163"
                />
                <path
                    d="M71.437 21.8397C71.437 22.3472 71.2865 22.8433 71.0045 23.2653C70.7225 23.6873 70.3217 24.0163 69.8528 24.2105C69.3839 24.4047 68.868 24.4555 68.3702 24.3565C67.8725 24.2575 67.4152 24.0131 67.0563 23.6542C66.6974 23.2953 66.453 22.8381 66.354 22.3403C66.255 21.8425 66.3058 21.3265 66.5 20.8576C66.6942 20.3887 67.0232 19.9879 67.4452 19.706C67.8672 19.424 68.3633 19.2735 68.8709 19.2735C69.2086 19.271 69.5434 19.3356 69.8559 19.4637C70.1683 19.5918 70.4522 19.7807 70.691 20.0195C70.9298 20.2583 71.1187 20.5422 71.2468 20.8546C71.3749 21.1671 71.4396 21.502 71.437 21.8397Z"
                    fill="#837163"
                />
                <path
                    d="M61.4135 8.66771C61.4135 9.17525 61.263 9.6714 60.9811 10.0934C60.6991 10.5154 60.2983 10.8443 59.8294 11.0385C59.3605 11.2328 58.8445 11.2836 58.3467 11.1846C57.849 11.0855 57.3917 10.8411 57.0328 10.4823C56.6739 10.1234 56.4296 9.66614 56.3306 9.16835C56.2315 8.67057 56.2823 8.15459 56.4766 7.68569C56.6708 7.21678 56.9997 6.81601 57.4217 6.53404C57.8437 6.25207 58.3398 6.10156 58.8474 6.10156C59.5264 6.10654 60.1763 6.3785 60.6564 6.85867C61.1366 7.33885 61.4086 7.98866 61.4135 8.66771Z"
                    fill="#837163"
                />
                <path
                    d="M47.2495 21.8397C47.2495 22.3472 47.099 22.8433 46.817 23.2653C46.535 23.6873 46.1343 24.0163 45.6654 24.2105C45.1965 24.4047 44.6805 24.4555 44.1827 24.3565C43.685 24.2575 43.2277 24.0131 42.8688 23.6542C42.5099 23.2953 42.2655 22.8381 42.1665 22.3403C42.0675 21.8425 42.1183 21.3265 42.3125 20.8576C42.5067 20.3887 42.8357 19.9879 43.2577 19.706C43.6797 19.424 44.1758 19.2735 44.6834 19.2735C45.0211 19.271 45.3559 19.3356 45.6684 19.4637C45.9808 19.5918 46.2647 19.7807 46.5035 20.0195C46.7423 20.2583 46.9312 20.5422 47.0593 20.8546C47.1874 21.1671 47.2521 21.502 47.2495 21.8397Z"
                    fill="#837163"
                />
                <path
                    d="M38.2651 8.66771C38.2651 9.17525 38.1146 9.6714 37.8327 10.0934C37.5507 10.5154 37.1499 10.8443 36.681 11.0385C36.2121 11.2328 35.6961 11.2836 35.1983 11.1846C34.7006 11.0855 34.2433 10.8411 33.8844 10.4823C33.5255 10.1234 33.2811 9.66614 33.1821 9.16835C33.0831 8.67057 33.134 8.15459 33.3282 7.68569C33.5224 7.21678 33.8513 6.81601 34.2733 6.53404C34.6953 6.25207 35.1914 6.10156 35.699 6.10156C36.3796 6.10156 37.0323 6.37193 37.5135 6.85318C37.9948 7.33442 38.2651 7.98713 38.2651 8.66771Z"
                    fill="#837163"
                />
                <path
                    d="M22.5386 20.238C22.5386 20.7455 22.388 21.2417 22.1061 21.6637C21.8241 22.0857 21.4234 22.4146 20.9545 22.6088C20.4856 22.8031 19.9696 22.8539 19.4718 22.7549C18.974 22.6559 18.5168 22.4115 18.1579 22.0526C17.799 21.6937 17.5546 21.2364 17.4556 20.7387C17.3565 20.2409 17.4073 19.7249 17.6016 19.256C17.7958 18.7871 18.1247 18.3863 18.5467 18.1043C18.9687 17.8224 19.4649 17.6719 19.9724 17.6719C20.653 17.6719 21.3057 17.9422 21.787 18.4235C22.2682 18.9047 22.5386 19.5574 22.5386 20.238Z"
                    fill="#837163"
                />
                <path
                    d="M13.6401 7.31615C13.6401 7.82369 13.4896 8.31983 13.2076 8.74184C12.9257 9.16384 12.5249 9.49274 12.056 9.68697C11.5871 9.8812 11.0711 9.93201 10.5733 9.83299C10.0755 9.73398 9.61827 9.48959 9.25939 9.1307C8.90051 8.77182 8.65614 8.31457 8.55713 7.81679C8.45811 7.319 8.50891 6.80303 8.70314 6.33412C8.89736 5.86522 9.22628 5.46445 9.64829 5.18248C10.0703 4.9005 10.5664 4.75 11.074 4.75C11.411 4.75 11.7446 4.81637 12.056 4.94533C12.3673 5.07429 12.6502 5.26331 12.8885 5.5016C13.1268 5.73989 13.3158 6.02278 13.4448 6.33412C13.5737 6.64546 13.6401 6.97916 13.6401 7.31615Z"
                    fill="#837163"
                />
            </g>
            <defs>
                <clipPath id="clip0_25214_66713">
                    <rect width="329" height="27.0302" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default LegacyModalOrnament;

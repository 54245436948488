const MobileMenuIcon = ({ width = '24', height = '25', color = 'white' }: { width?: string; height?: string; color?: string }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4 12.7695H20M4 6.76953H20M4 18.7695H20"
                stroke={color}
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

export default MobileMenuIcon;

const homeV2 = {
    seo: {
        title: 'Be Soul: Aplicación de planificación de legados',
        description:
            'Explora la belleza de compartir recuerdos en línea y embarcate en tu viaje de planificación de legados con nuestra aplicación. Crea un legado digital que perdure para siempre',
        carousel: {
            altImage1:
                'Revive tus recuerdos a través de fotos y vídeos y compártelos con tus seres queridos en nuestra aplicación de legado digital',
            altImage2: 'Organiza tus documentos y testamento con nuestra app para una planificación de legado digital efectiva',
            altImage3: 'Guarda la voz de tus ancestros y su sabiduría para la planificación de tu legado digital'
        },
        digitalMemoriesAltImage: 'Memoriales digitales de un querido perro y una señora, celebrando vidas y recuerdos en línea',
        videoCarousel: {
            video1: 'Liam relata la facilidad de preservar recuerdos con nuestra app de legados digitales - ver en YouTube',
            video2: 'Valeria comparte cómo nuestra app le ayudó a ver un legado digital significativo - ver en YouTube',
            video3: 'Testimonio de Paige sobre su experiencia con nuestra app de legados digitales - ver en YouTube'
        }
    },
    banner: {
        title1: 'Be Soul',
        title2: 'Un legado de amor y recuerdos',
        subtitle1: 'Explora una forma única de celebrar la vida y dejar una huella eterna.'
    },
    description: {
        title: 'Be Soul, la App más completa para acompañarte en el final de la vida.',
        subtitle:
            'Nuestras herramientas están diseñadas para acompañarte a ti y a tus seres queridos, brindando guía y consuelo en este importante proceso.',
        text1: 'Crea tu legado y elige si quieres...',
        tag1: 'A. Liberarlo de forma instantanea',
        tag2: 'B. Planear cuando vas a liberarlo',
        tag3: 'C. Liberarlo Postmortem',
        legacyModal: {
            title: 'Legados en Be Soul App',
            text: 'Creamos una herramienta que te permite preservar tus recuerdos y dejar un legado duradero para que tus seres queridos los atesoren, incluso cuando ya no estés. Desarrollamos la función de legados para que puedas guardar y compartir tus memorias más queridas de manera especial y significativa. Puedes incluir fotos, videos, audios o documentos importantes, y decidir cuándo se liberan: en una fecha específica, de inmediato, o incluso después de tu partida. Así, tus historias y sentimientos seguirán acompañando a quienes más amas.',
            button1: 'Conoce más',
            button2: 'Un espacio para recuerdos valiosos',
            button3: 'Una voz que se atesora para siempre',
            button4: 'Cápsula del tiempo'
        },
        memorialModal: {
            title: 'Memoriales en Be Soul App',
            text: 'En la app de Be Soul, los memoriales ofrecen un espacio íntimo y significativo para rendir homenaje a los seres queridos que ya no están. A través de esta función, los usuarios pueden crear un tributo personalizado, compartiendo recuerdos, fotos y mensajes que mantengan viva la memoria de aquellos que fueron importantes en sus vidas. Es una forma de mantener presente su legado, creando un lugar de encuentro donde familiares y amigos pueden conectarse, compartir emociones y recordar los momentos especiales vividos juntos.',
            button1: 'Honra a un ser querido',
            button2: 'Honra a tu mascota'
        }
    },
    features: {
        title: 'Documenta tu día a día y compártelo con tus seres queridos...',
        card1: {
            title: 'Crea videos diarios',
            text: 'Captura cada día con videos que narran tu historia. Pequeños momentos, grandes recuerdos.'
        },
        card2: {
            title: 'Registra tus emociones',
            text: 'Después de subir tu video diario, refleja cómo te sientes y lleva un seguimiento de tus emociones para conectarte más contigo mismo.'
        },
        card3: {
            title: 'Crea grupos familiares',
            text: 'Conecta a tu familia en un espacio íntimo y seguro. Comparte recuerdos, mensajes y vive la cercanía, sin importar la distancia.'
        }
    },
    soulGuide: {
        title: 'Guía del Alma',
        new: 'Nuevo',
        subtitle: 'Explora tu ser interior con herramientas diseñadas para guiarte en tu viaje personal.',
        card1: {
            title: 'Número maestro',
            text: 'Descubre los significados ocultos detrás de los números en tu vida.'
        },
        card2: {
            title: 'Carta natal',
            text: 'Conoce como los astros influyen en tu camino y descubre aspectos de tu personalidad.'
        },
        card3: {
            title: 'Intérprete de sueños',
            text: 'Comprende los mensajes de tus sueños y encuentra respuestas en tu subconsciente.'
        },
        card4: {
            title: 'Libro de vida',
            text: 'Escribe y revisa la historia de tu vida, desde los pequeños hasta los grandes momentos.'
        },
        card5: {
            title: 'Conversar con Soul Guide',
            text: 'Recibe orientación y consejos para navegar los momentos más difíciles y hallar paz.'
        },
        card6: {
            title: 'Oráculo',
            text: 'Descubre predicciones semanales personalizadas, basadas en tu carta natal'
        },
        learnMore: 'Conoce más'
    },
    emotionalSupport: {
        title: 'SoulGuide, soporte emocional',
        subtitle:
            'Soulguide es una IA de soporte emocional a tu alcance, recibe acompañamiento en los momentos más difíciles. No estás solo, estamos aquí para escucharte y apoyarte cuando más lo necesites.',
        card1: { title: 'Escríbele a SoulGuide', text: 'Conversa con SoulGuide para recibir ayuda y soporte de forma escrita.' },
        card2: { title: 'Habla con SoulGuide', text: 'Llama gratis y conversa para recibir ayuda y soporte.' },
        card3: {
            title: '+1 (888) 998-1563',
            text1: 'Número gratuito en Inglés',
            text2: ' para quienes no cuentan con los recursos para una terapia profesional'
        },
        card4: {
            title: ' +1 (850) 7357297',
            text1: 'Número gratuito en Español',
            text2: ' para quienes no cuentan con los recursos para una terapia profesional'
        }
    },
    testimonials: {
        title: 'Testimonios',
        subtitle: 'Descubre cómo otros han encontrado apoyo y conexión con Be Soul'
    },
    downloadApp: {
        title: 'Descarga Be Soul y empieza tu viaje de conexión y reflexión'
    },
    FQ: {
        title: 'Preguntas Frecuentes',
        '1': {
            question: '¿Qué es y para quién es Be Soul App?',
            answer: 'Be Soul está dirigida a cualquier persona que deseen dejar un legado duradero y significativo para sus seres queridos, incluso después de su partida. Permite crear y compartir recuerdos personales, mensajes y reflexiones especiales que perdurarán en el tiempo, logrando mantener viva la memoria y el legado de quien lo utiliza.'
        },
        '2': {
            question: '¿Es posible crear un perfil en Be Soul para alguien que ya falleció?',
            answer: 'Sí, es posible crear un perfil en Be Soul para alguien que ya falleció. La aplicación permite honrar y preservar la memoria de seres queridos fallecidos al permitir la creación de perfiles con sus recuerdos, mensajes y reflexiones. Esto brinda a los usuarios la oportunidad de recordar y compartir la vida y el legado de la persona fallecida.'
        },
        '3': {
            question: '¿Qué cosas puedo compartir en Be Soul?',
            answer: 'En Be Soul puedes compartir una variedad de elementos significativos, tales como fotografías, videos, documentos importantes, recuerdos personales, historias familiares y recetas de cocina con las personas que sea de su elección.'
        },
        '4': {
            question: '¿Cómo funciona el proceso de liberación de los recuerdos asignados a mis seres queridos en Be Soul?',
            answer: 'Be Soul funciona como un testamento/Memorial digital, asegurando que todos los recuerdos y contenido sean preservados y desbloqueados después del fallecimiento de la persona o en una fecha específica. De esta manera, se garantiza que las memorias y las historias importantes perduren en el tiempo, permitiendo que los seres queridos puedan acceder a ellos, encontrar consuelo y conexión emocional.'
        },
        '5': {
            question: '¿Quiénes pueden acceder a tus recuerdos?',
            answer: 'Solo las personas a las que les hayas compartido los recuerdos podrán acceder a ellos. En Be Soul, la privacidad y la selección de audiencia son fundamentales. Puedes elegir de manera selectiva a quién deseas compartir tus recuerdos, asegurando que solo las personas designadas puedan acceder a ellos y mantener así la confidencialidad y la intimidad de tus recuerdos personales.'
        },
        '6': {
            question: '¿Cual es el proceso de validación de un fallecimiento en la aplicación?',
            answer: 'Be Soul está dirigida a cualquier persona que deseen dejar un legado duradero y significativo para sus seres queridos, incluso después de su partida. Permite crear y compartir recuerdos personales, mensajes y reflexiones especiales que perdurarán en el tiempo, logrando mantener viva la memoria y el legado de quien lo utiliza.'
        },
        '7': {
            question: '¿Qué otras funciones tiene la app?',
            answer: 'Además de dejar y recibir testamentos a tus seres queridos, en Be Soul también puedes acceder a los perfiles de las personas fallecidas y dejarles insignias con dedicatorias, lo que permitirá que cualquier persona que visite el perfil pueda apreciar lo valioso que esa persona fue para sus seres queridos. Esta función brinda la oportunidad de honrar y recordar a aquellos que han partido, compartiendo testimonios y expresiones de afecto en un espacio dedicado a su memoria.'
        }
    },
    cookies: {
        title: 'Este sitio usas cookies para ofrecerte una mejor experiencia. Lee más sobre las politicas de las cookies',
        allCookies: 'Aceptar TODAS las cookies del sitio',
        analytics: 'Google Analytics',
        button1: 'Configurar',
        button1Alternate: 'Guardar cambios',
        button2: 'Aceptar cookies'
    }
};

export default homeV2;

import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import AppStoreIcon from '../../assets/icons/svg/app_store_icon.svg';
import AppStoreLogoEng from '../../assets/images/svg/app_store_logo_eng.svg';
import { useTranslation } from 'react-i18next';

const AppStore = ({
    width = { xs: '100px', md: '143px' }
}: {
    width?: { xs?: string; sm?: string; md?: string; lg?: string; xl?: string };
}) => {
    const { i18n } = useTranslation();
    const language = i18n.language;
    return (
        <Link to="https://apps.apple.com/us/app/be-soul/id6444774949" target="_blank">
            <Box
                sx={(theme) => ({
                    height: 'max-content',
                    [theme.breakpoints.up('xs')]: {
                        width: width.xs
                    },
                    [theme.breakpoints.up('sm')]: {
                        width: width.sm
                    },
                    [theme.breakpoints.up('md')]: {
                        width: width.md
                    },
                    [theme.breakpoints.up('lg')]: {
                        width: width.lg
                    },
                    [theme.breakpoints.up('xl')]: {
                        width: width.xl
                    }
                })}
            >
                <img src={language === 'en-US' ? AppStoreLogoEng : AppStoreIcon} alt="Get it on Apple App Store" width="100%" />
            </Box>
        </Link>
    );
};

export default AppStore;

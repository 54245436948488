const home = {
    seo: {
        title: 'Be Soul: Aplicación de planificación de legados',
        description:
            'Explora la belleza de compartir recuerdos en línea y embarcate en tu viaje de planificación de legados con nuestra aplicación. Crea un legado digital que perdure para siempre',
        carousel: {
            altImage1:
                'Revive tus recuerdos a través de fotos y vídeos y compártelos con tus seres queridos en nuestra aplicación de legado digital',
            altImage2: 'Organiza tus documentos y testamento con nuestra app para una planificación de legado digital efectiva',
            altImage3: 'Guarda la voz de tus ancestros y su sabiduría para la planificación de tu legado digital'
        },
        digitalMemoriesAltImage: 'Memoriales digitales de un querido perro y una señora, celebrando vidas y recuerdos en línea',
        videoCarousel: {
            video1: 'George comparte su descubrimiento de Be Soul: Una aplicación esencial para salvaguardar y organizar tu legado digital en el mundo en línea actual',
            video2: 'Inspiración para Padres sobre Cómo Dejar un Legado con Be Soul - Ver en YouTube',
            video3: 'El viaje de un director de tecnología con Be Soul - Ver en YouTube'
        }
    },
    banner: {
        title1: 'Conecta con Seres Queridos, Planifica tu ',
        title2: ' Legado Digital',
        subtitle1: 'Tu legado, tu decisión, tu historia'
    },
    welcome: {
        title: 'Planifica tu Legado Digital y prepara el final de la vida',
        subtitle: 'La app que te acompaña en los momentos finales de la vida',
        badges: {
            '1': {
                '1': 'Planifica tu legado ',
                '2': 'antes de partir'
            },
            '2': {
                '1': 'Conmemora ',
                '2': 'a aquellos que ya no están'
            },
            '3': {
                '1': 'Encuentra herramientas ',
                '2': 'que aumenten tu bienestar'
            }
        }
    },
    shareLegacy: {
        title: {
            '1': 'Comparte tu Legado Digital ',
            '2': 'con seres queridos'
        },
        subtitle:
            'Guarda tus recuerdos mas preciados en un espacio digital seguro y compártelos con tus seres queridos en una fecha específica o post mortem',
        button: 'Comienza ahora',
        carousel: {
            text1: 'Revive tus recuerdos mediante fotos o videos y compártelos con tus seres queridos',
            text2: 'Guarda la voz de tus ancestros, su sabiduría y enseñanzas de generación en generación',
            text3: 'Organiza tus documentos, archivos y testamento antes de tu partida compartiendolos con quienes desees'
        }
    },
    digitalMemorials: {
        title: 'Memoriales: preserva el recuerdo de quienes amaste ',
        label: 'Próximamente',
        subtitle: 'Es un espacio para crear perfiles conmemorativos de seres queridos que ya no están con nosotros',
        button: 'Descarga Be Soul'
    },
    testimonials: {
        title: 'Legados Digitales y la experiencia de nuestros usuarios',
        subtitle: 'Conoce más sobre su opinión'
    },
    FQ: {
        title: 'Preguntas Frecuentes',
        '1': {
            question: '¿Qué es y para quién es Be Soul App?',
            answer: 'Be Soul está dirigida a cualquier persona que deseen dejar un legado duradero y significativo para sus seres queridos, incluso después de su partida. Permite crear y compartir recuerdos personales, mensajes y reflexiones especiales que perdurarán en el tiempo, logrando mantener viva la memoria y el legado de quien lo utiliza.'
        },
        '2': {
            question: '¿Es posible crear un perfil en Be Soul para alguien que ya falleció?',
            answer: 'Sí, es posible crear un perfil en Be Soul para alguien que ya falleció. La aplicación permite honrar y preservar la memoria de seres queridos fallecidos al permitir la creación de perfiles con sus recuerdos, mensajes y reflexiones. Esto brinda a los usuarios la oportunidad de recordar y compartir la vida y el legado de la persona fallecida.'
        },
        '3': {
            question: '¿Qué cosas puedo compartir en Be Soul?',
            answer: 'En Be Soul puedes compartir una variedad de elementos significativos, tales como fotografías, videos, documentos importantes, recuerdos personales, historias familiares y recetas de cocina con las personas que sea de su elección.'
        },
        '4': {
            question: '¿Cómo funciona el proceso de liberación de los recuerdos asignados a mis seres queridos en Be Soul?',
            answer: 'Be Soul funciona como un testamento/Memorial digital, asegurando que todos los recuerdos y contenido sean preservados y desbloqueados después del fallecimiento de la persona o en una fecha específica. De esta manera, se garantiza que las memorias y las historias importantes perduren en el tiempo, permitiendo que los seres queridos puedan acceder a ellos, encontrar consuelo y conexión emocional.'
        },
        '5': {
            question: '¿Quiénes pueden acceder a tus recuerdos?',
            answer: 'Solo las personas a las que les hayas compartido los recuerdos podrán acceder a ellos. En Be Soul, la privacidad y la selección de audiencia son fundamentales. Puedes elegir de manera selectiva a quién deseas compartir tus recuerdos, asegurando que solo las personas designadas puedan acceder a ellos y mantener así la confidencialidad y la intimidad de tus recuerdos personales.'
        },
        '6': {
            question: '¿Cual es el proceso de validación de un fallecimiento en la aplicación?',
            answer: 'Be Soul está dirigida a cualquier persona que deseen dejar un legado duradero y significativo para sus seres queridos, incluso después de su partida. Permite crear y compartir recuerdos personales, mensajes y reflexiones especiales que perdurarán en el tiempo, logrando mantener viva la memoria y el legado de quien lo utiliza.'
        },
        '7': {
            question: '¿Qué otras funciones tiene la app?',
            answer: 'Además de dejar y recibir testamentos a tus seres queridos, en Be Soul también puedes acceder a los perfiles de las personas fallecidas y dejarles insignias con dedicatorias, lo que permitirá que cualquier persona que visite el perfil pueda apreciar lo valioso que esa persona fue para sus seres queridos. Esta función brinda la oportunidad de honrar y recordar a aquellos que han partido, compartiendo testimonios y expresiones de afecto en un espacio dedicado a su memoria.'
        }
    },
    cookies: {
        title: 'Este sitio usas cookies para ofrecerte una mejor experiencia. Lee más sobre las politicas de las cookies',
        allCookies: 'Aceptar TODAS las cookies del sitio',
        analytics: 'Google Analytics',
        clarity: 'Microsoft Clarity',
        button1: 'Configurar',
        button1Alternate: 'Guardar cambios',
        button2: 'Aceptar todas las cookies'
    }
};

export default home;

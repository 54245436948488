import { Typography as TypographyUI } from '@mui/material';
import { ReactNode } from 'react';

interface PropertyBreakPoints {
    xs?: string | null;
    sm?: string | null;
    md?: string | null;
    lg?: string | null;
    xl?: string | null;
    xxl?: string | null;
}

interface SxBreakpoints {
    xs?: object | null;
    sm?: object | null;
    md?: object | null;
    lg?: object | null;
    xl?: object | null;
    xxl?: object | null;
}

type PresetOptions =
    | 'displayLarge'
    | 'displayMedium'
    | 'displaySmall'
    | 'headlineLarge'
    | 'headlineMedium'
    | 'headlineSmall'
    | 'titleLarge'
    | 'titleMedium'
    | 'titleSmall'
    | 'labelLarge'
    | 'labelMedium'
    | 'labelSmall'
    | 'bodyLarge'
    | 'bodyMedium'
    | 'bodySmall';

type Presets = {
    [key: string]: { fontSize: string };
};

interface PresetBreakpoints {
    xs?: PresetOptions;
    sm?: PresetOptions;
    md?: PresetOptions;
    lg?: PresetOptions;
    xl?: PresetOptions;
    xxl?: PresetOptions;
}

export interface TextProps {
    children: ReactNode;
    component?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'div' | 'span' | 'li';
    sx?: SxBreakpoints;
    fontFamily?: 'Inter' | 'Merriweather';
    lineHeight?: string | PropertyBreakPoints;
    width?: string | PropertyBreakPoints;
    height?: string | PropertyBreakPoints;
    margin?: string | PropertyBreakPoints;
    padding?: string | PropertyBreakPoints;
    fontWeight?: number;
    textColor?: string;
    preset?: string | PresetBreakpoints;
    disabled?: boolean;
    onClick?: () => void;
    maxWidth?: string;
    noWrap?: boolean;
}

const presets: Presets = {
    displayLarge: { fontSize: '57px' },
    displayMedium: { fontSize: '45px' },
    displaySmall: { fontSize: '36px' },
    headlineLarge: { fontSize: '32px' },
    headlineMedium: { fontSize: '28px' },
    headlineSmall: { fontSize: '24px' },
    titleLarge: { fontSize: '18px' },
    titleMedium: { fontSize: '16px' },
    titleSmall: { fontSize: '14px' },
    labelLarge: { fontSize: '14px' },
    labelMedium: { fontSize: '12px' },
    labelSmall: { fontSize: '11px' },
    bodyLarge: { fontSize: '16px' },
    bodyMedium: { fontSize: '14px' },
    bodySmall: { fontSize: '12px' }
};

const Text = ({
    children,
    component = 'p',
    sx = { xs: {}, sm: {}, md: {}, lg: {}, xl: {}, xxl: {} },
    fontFamily = 'Inter',
    preset,
    textColor = '#45565f',
    fontWeight,
    lineHeight,
    width,
    height,
    padding,
    margin,
    disabled,
    onClick,
    maxWidth = 'auto',
    noWrap = false
}: TextProps) => {
    let fontSize: string;
    let fontSizeBreakpoints: PropertyBreakPoints;
    const { xs: extraSmallSx, sm: smallSx, md: mediumSx, lg: largeSx, xl: extraLargeSx, xxl: extraExtraLargeSx } = sx;

    if (typeof preset === 'string') {
        fontSize = presets[preset].fontSize;
    } else if (typeof preset === 'object') {
        fontSizeBreakpoints = {
            xs: preset.xs ? presets[preset.xs].fontSize : null,
            sm: preset.sm ? presets[preset.sm].fontSize : null,
            md: preset.md ? presets[preset.md].fontSize : null,
            lg: preset.lg ? presets[preset.lg].fontSize : null,
            xl: preset.xl ? presets[preset.xl].fontSize : null,
            xxl: preset.xxl ? presets[preset.xxl].fontSize : null
        };
    }

    return (
        <TypographyUI
            onClick={onClick}
            noWrap={noWrap}
            sx={(theme) => ({
                fontFamily,
                color: disabled ? theme.palette.grey[400] : textColor,
                fontWeight: fontWeight,
                lineHeight: typeof lineHeight === 'string' || typeof lineHeight === 'number' ? lineHeight : undefined,
                width: typeof width === 'string' || typeof width === 'number' ? width : undefined,
                height: typeof height === 'string' || typeof height === 'number' ? height : undefined,
                padding: typeof padding === 'string' || typeof padding === 'number' ? padding : undefined,
                margin: typeof margin === 'string' || typeof margin === 'number' ? margin : undefined,
                fontSize: fontSize,
                ...sx,
                [theme.breakpoints.up('xs')]: {
                    lineHeight: typeof lineHeight === 'object' ? lineHeight['xs'] : undefined,
                    width: typeof width === 'object' ? width['xs'] : undefined,
                    height: typeof height === 'object' ? height['xs'] : undefined,
                    margin: typeof margin === 'object' ? margin['xs'] : undefined,
                    padding: typeof padding === 'object' ? padding['xs'] : undefined,
                    fontSize: fontSizeBreakpoints ? fontSizeBreakpoints['xs'] : undefined,
                    ...extraSmallSx
                },
                [theme.breakpoints.up('sm')]: {
                    lineHeight: typeof lineHeight === 'object' ? lineHeight['sm'] : undefined,
                    width: typeof width === 'object' ? width['sm'] : undefined,
                    height: typeof height === 'object' ? height['sm'] : undefined,
                    margin: typeof margin === 'object' ? margin['sm'] : undefined,
                    padding: typeof padding === 'object' ? padding['sm'] : undefined,
                    fontSize: fontSizeBreakpoints ? fontSizeBreakpoints['sm'] : undefined,
                    ...smallSx
                },
                [theme.breakpoints.up('md')]: {
                    lineHeight: typeof lineHeight === 'object' ? lineHeight['md'] : undefined,
                    width: typeof width === 'object' ? width['md'] : undefined,
                    height: typeof height === 'object' ? height['md'] : undefined,
                    margin: typeof margin === 'object' ? margin['md'] : undefined,
                    padding: typeof padding === 'object' ? padding['md'] : undefined,
                    fontSize: fontSizeBreakpoints ? fontSizeBreakpoints['md'] : undefined,
                    ...mediumSx
                },
                [theme.breakpoints.up('lg')]: {
                    lineHeight: typeof lineHeight === 'object' ? lineHeight['lg'] : undefined,
                    width: typeof width === 'object' ? width['lg'] : undefined,
                    height: typeof height === 'object' ? height['lg'] : undefined,
                    margin: typeof margin === 'object' ? margin['lg'] : undefined,
                    padding: typeof padding === 'object' ? padding['lg'] : undefined,
                    fontSize: fontSizeBreakpoints ? fontSizeBreakpoints['lg'] : undefined,
                    ...largeSx
                },
                [theme.breakpoints.up('xl')]: {
                    width: typeof width === 'object' ? width['xl'] : undefined,
                    height: typeof height === 'object' ? height['xl'] : undefined,
                    margin: typeof margin === 'object' ? margin['xl'] : undefined,
                    padding: typeof padding === 'object' ? padding['xl'] : undefined,
                    ...extraLargeSx
                },
                [`@media (width >= 1960px)`]: {
                    lineHeight: typeof lineHeight === 'object' ? lineHeight['lg'] : undefined,
                    width: typeof width === 'object' ? width['xxl'] : undefined,
                    height: typeof height === 'object' ? height['xxl'] : undefined,
                    margin: typeof margin === 'object' ? margin['xxl'] : undefined,
                    padding: typeof padding === 'object' ? padding['xxl'] : undefined,
                    fontSize: fontSizeBreakpoints ? fontSizeBreakpoints['xxl'] : undefined,
                    ...extraExtraLargeSx
                },
                textOverflow: 'ellipsis',
                wordBreak: 'break-word',
                maxWidth: maxWidth
            })}
            component={component}
        >
            {children}
        </TypographyUI>
    );
};

export default Text;

const BeSoulIcon = ({
    color = '#FFFFFF',
    width = '161',
    height = 'auto',
    style
}: {
    color?: string;
    width?: string;
    height?: string;
    style?: Object;
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 161 164" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
            <path
                d="M139.602 138.606C125.869 152.642 107.136 161.805 86.2731 163.227L91.1379 153.231C92.5412 150.357 93.1922 147.352 93.1922 144.414C93.1922 134.887 86.337 126.04 76.0505 124.419C62.0844 122.209 54.616 106.788 61.5464 94.5031L73.003 74.2117C75.0987 72.5082 77.7248 71.5664 80.494 71.5664C84.1435 71.5627 87.541 73.2023 89.8097 76.0502L139.602 138.606Z"
                fill={color}
            />
            <path
                d="M161 81.0518C161 100.548 153.979 119.177 141.18 133.799L93.1334 73.4388C91.3538 71.2063 89.07 69.5216 86.4965 68.4972C84.619 67.7468 82.5873 67.3528 80.4954 67.3528C79.1748 67.3528 77.873 67.5104 76.6239 67.8181C73.1926 68.6586 70.1187 70.6059 67.8612 73.4388L19.8339 133.803C5.6307 117.616 -1.32602 96.9233 0.209051 75.236C3.05344 35.0058 35.6699 3.04527 76.0783 0.891552C77.572 0.812758 79.0619 0.771484 80.5443 0.771484C101.26 0.771484 120.761 8.49335 135.871 22.7814C152.076 38.0976 161 58.7943 161 81.0518Z"
                fill={color}
            />
        </svg>
    );
};

export default BeSoulIcon;

const appInformation = {
    banner1: {
        title: 'BE SOUL THE ONLY APP THAT HELPS YOU PRESERVE YOUR LEGACY, DOWNLOAD IT NOW AND DISCOVER MUCH MORE.',
        subtitle: 'More functions available by downloading the application'
    },
    banner2: {
        title: 'Discover many more features in the Be Soul App',
        subtitle: 'Let the universe guide you through Soulguide and find insights that align with your purpose.'
    }
};

export default appInformation;

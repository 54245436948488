const ArrowUpIcon = ({ color = '#FFFFFF' }: { color?: string }) => {
    return (
        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.7929 7.20712C12.1834 7.59764 12.8166 7.59763 13.2071 7.2071C13.5976 6.81657 13.5976 6.18341 13.2071 5.79289L7.71645 0.302361C7.71336 0.299187 7.71024 0.296029 7.7071 0.292886C7.51106 0.0968472 7.25387 -0.000779505 6.99693 4.7651e-06C6.74199 0.000751878 6.48728 0.0983844 6.29276 0.292902C6.28963 0.296035 6.28652 0.299185 6.28344 0.30235L0.792403 5.7935C0.401884 6.18403 0.40189 6.8172 0.792419 7.20772C1.18295 7.59824 1.81611 7.59823 2.20663 7.2077L6.99995 2.41427L11.7929 7.20712Z"
                fill={color}
            />
        </svg>
    );
};

export default ArrowUpIcon;

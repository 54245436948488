const appInformation = {
    banner1: {
        title: 'BE SOUL LA ÚNICA APP QUE TE AYUDA A PRESERVAR TU LEGADO, DESCARGALA AHORA Y DESCUBRE MUCHO MAS',
        subtitle: 'Más funciones disponibles descargando la aplicación'
    },
    banner2: {
        title: 'Descubre muchas más funcionalidades en la App Be Soul',
        subtitle: 'Permite que el universo te guíe a través de Soulguide y encuentra conocimientos que se alinean con tu propósito'
    }
};

export default appInformation;

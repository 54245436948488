import { useState } from 'react';
import {
    Box,
    Collapse,
    Divider,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    Menu,
    MenuItem,
    Stack,
    useMediaQuery,
    useTheme
} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import PersonIcon from '@mui/icons-material/Person';
import Text from 'ui-component/Text/Text';
import { useTranslation } from 'react-i18next';
import CustomLink from 'ui-component/CustomLink';
import CustomButton from 'ui-component/CustomButtom';
import { useDispatch, useSelector } from 'react-redux';
import useAuth from 'hooks/useAuth';
import LanguagesIcon from 'ui-component/Icons/LanguagesIcon';
import ArrowDownIcon from 'ui-component/Icons/ArrowDownIcon';
import ArrowUpIcon from 'ui-component/Icons/ArrowUpIcon';
import BeSoulIcon from 'ui-component/Icons/BeSoulIcon';
import MobileMenuIcon from 'ui-component/Icons/MobileMenuIcon';
import MemorialModal from 'views/pages/HomeV2/components/MemorialModal';
import CloseIcon from '@mui/icons-material/Close';
import { resetUser } from 'store/slices/user';
import LegacyModal from 'views/pages/HomeV2/components/LegacyModal';

const LanguageMenu = () => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const changeLanguage = (locale: string) => {
        i18n.changeLanguage(locale);
        handleClose();
    };

    return (
        <>
            <CustomButton onClick={handleClick}>
                <LanguagesIcon color="#45565F" style={{ marginRight: '5px' }} />
                {open ? <ArrowUpIcon color="#45565F" /> : <ArrowDownIcon color="#45565F" />}
            </CustomButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{ sx: { backgroundColor: theme.palette.common.white } }}
            >
                <MenuItem onClick={() => changeLanguage('es-419')}>
                    <Text preset="titleLarge" fontFamily="Merriweather" fontWeight={700}>
                        {t('common.header.languages.spanish')}
                    </Text>
                </MenuItem>
                <MenuItem onClick={() => changeLanguage('en-US')}>
                    <Text preset="titleLarge" fontFamily="Merriweather" fontWeight={700}>
                        {t('common.header.languages.english')}
                    </Text>
                </MenuItem>
            </Menu>
        </>
    );
};

const MobileMenuList = ({ handleClose, handleLogout }: { handleClose: () => void; handleLogout: () => void }) => {
    const { t, i18n } = useTranslation();
    const [openNested, setOpenNested] = useState(false);

    const { isLoggedIn } = useAuth();
    const isPhoneXs = useMediaQuery('(max-width: 320px)');
    const headerNavBarElements = [
        { id: 'home', title: t('common.header.home'), link: '/' },
        { id: 'legacy', title: t('common.header.legacy'), link: 'https://legacy.besoul.app/create-a-digital-legacy' },
        { id: 'memorial', title: t('common.header.memorials'), link: '/' },
        // { id: 'soulGuide', title: t('common.header.soulGuide'), link: '#soul-guide' },
        { id: 'plans', title: t('common.header.plans'), link: 'https://legacy.besoul.app/plans-besoul' },
        {
            id: 'journal',
            title: t('common.header.journal'),
            link: 'https://legacy.besoul.app/besoul-start-your-video-journals'
        },
        { id: 'blog', title: t('common.header.blog'), link: 'https://blog.besoul.app/' }
    ];

    const handleOpenNested = () => {
        setOpenNested(!openNested);
    };

    const changeLanguage = (locale: string) => {
        i18n.changeLanguage(locale);
        handleClose();
    };

    return (
        <Box sx={{ width: '100%', backgroundColor: '#F6F2F1' }}>
            <List disablePadding>
                {isLoggedIn && (
                    <ListItem sx={{ borderTop: '1px solid #B0BEC56E' }}>
                        <CustomLink to={'/app/memorial/feed'} sx={{ width: '100%', padding: 0 }}>
                            <Text fontWeight={400} preset="titleSmall" sx={{ xs: { textAlign: 'center' } }} fontFamily="Merriweather">
                                {t('common.header.createMemorial')}
                            </Text>
                        </CustomLink>
                    </ListItem>
                )}

                {headerNavBarElements.map((element) => {
                    if (element.id === 'blog') {
                        return (
                            <ListItem key={element.id} sx={{ borderTop: '1px solid #B0BEC56E', borderBottom: '1px solid #B0BEC56E' }}>
                                <CustomLink to={element.link} target="_blank" onClick={() => handleClose()} sx={{ width: '100%' }}>
                                    <Text
                                        fontWeight={400}
                                        preset="titleSmall"
                                        sx={{ xs: { textAlign: 'center' } }}
                                        fontFamily="Merriweather"
                                    >
                                        {element.title}
                                    </Text>
                                </CustomLink>
                            </ListItem>
                        );
                    }

                    if (element.id === 'home') {
                        return (
                            <ListItem key={element.id} sx={{ borderTop: '1px solid #B0BEC56E' }}>
                                <CustomLink onClick={() => handleClose()} to={element.link} sx={{ width: '100%' }}>
                                    <Stack direction="column" alignItems="center">
                                        <Text
                                            fontWeight={400}
                                            preset="titleSmall"
                                            sx={{ xs: { textAlign: 'center', position: 'relative' } }}
                                            fontFamily="Merriweather"
                                        >
                                            {element.title}
                                        </Text>
                                        <Box sx={{ width: '74px', height: '5px', backgroundColor: '#FDBDCC', borderRadius: '2px' }} />
                                    </Stack>
                                </CustomLink>
                            </ListItem>
                        );
                    }

                    if (element.id === 'memorial') {
                        return (
                            <ListItem key={element.id} sx={{ borderTop: '1px solid #B0BEC56E' }}>
                                <MemorialModal buttonStyle={{ xs: { padding: 0, width: '100%' } }}>
                                    <Text
                                        fontWeight={400}
                                        preset="titleSmall"
                                        sx={{ xs: { textAlign: 'center', width: '100%' } }}
                                        fontFamily="Merriweather"
                                    >
                                        {element.title}
                                    </Text>
                                </MemorialModal>
                            </ListItem>
                        );
                    }

                    if (element.id === 'legacy') {
                        return (
                            <ListItem key={element.id} sx={{ borderTop: '1px solid #B0BEC56E' }}>
                                <LegacyModal buttonStyle={{ xs: { padding: 0, width: '100%' } }}>
                                    <Text
                                        fontWeight={400}
                                        preset="titleSmall"
                                        sx={{ xs: { textAlign: 'center', width: '100%' } }}
                                        fontFamily="Merriweather"
                                    >
                                        {element.title}
                                    </Text>
                                </LegacyModal>
                            </ListItem>
                        );
                    }

                    if (element.id === 'soulGuide') {
                        return (
                            <ListItem key={element.id} sx={{ borderTop: '1px solid #B0BEC56E' }}>
                                <CustomLink onClick={() => handleClose()} to={element.link} sx={{ width: '100%' }}>
                                    <Text
                                        fontWeight={400}
                                        preset="titleSmall"
                                        sx={{ xs: { textAlign: 'center' } }}
                                        fontFamily="Merriweather"
                                    >
                                        {element.title}
                                    </Text>
                                </CustomLink>
                                <Box
                                    component="span"
                                    position="absolute"
                                    right={isPhoneXs ? '15%' : '20%'}
                                    sx={{
                                        fontFamily: 'Merriweather',
                                        fontSize: '10px',
                                        borderRadius: '7px',
                                        padding: '2px 14px',
                                        lineHeight: '20px',
                                        background: `linear-gradient(90deg, #FED8C9, #FED8C9, #FDC1CB)`
                                    }}
                                >
                                    {t('common.header.new')}
                                </Box>
                            </ListItem>
                        );
                    }

                    return (
                        <ListItem key={element.id} sx={{ borderTop: '1px solid #B0BEC56E' }}>
                            <CustomLink onClick={() => handleClose()} to={element.link} sx={{ width: '100%' }}>
                                <Text fontWeight={400} preset="titleSmall" sx={{ xs: { textAlign: 'center' } }} fontFamily="Merriweather">
                                    {element.title}
                                </Text>
                            </CustomLink>
                        </ListItem>
                    );
                })}

                <ListItem onClick={handleOpenNested}>
                    <ListItemIcon sx={{ margin: '0 auto', display: 'flex', alignItems: 'center', columnGap: 1 }}>
                        <LanguagesIcon color="#45565F" width="30px" />
                        {openNested ? <ArrowUpIcon color="#95C7D8" /> : <ArrowDownIcon color="#95C7D8" />}
                    </ListItemIcon>
                </ListItem>
                <Collapse in={openNested} timeout="auto" unmountOnExit>
                    <Stack direction="column" justifyContent="center" sx={{ listStyle: 'none' }}>
                        <CustomButton sx={{ xs: { padding: '10px 0', color: '#fff' } }} onClick={() => changeLanguage('es')}>
                            <Text fontWeight={400} preset="titleSmall" fontFamily="Merriweather" sx={{ xs: { textAlign: 'center' } }}>
                                {t('common.header.languages.spanish')}
                            </Text>
                        </CustomButton>
                        <CustomButton sx={{ xs: { padding: '10px 0', color: '#fff' } }} onClick={() => changeLanguage('en')}>
                            <Text fontWeight={400} preset="titleSmall" fontFamily="Merriweather" sx={{ xs: { textAlign: 'center' } }}>
                                {t('common.header.languages.english')}
                            </Text>
                        </CustomButton>
                    </Stack>
                </Collapse>

                {isLoggedIn ? (
                    <ListItem sx={{ borderTop: '1px solid #B0BEC56E' }}>
                        <CustomButton onClick={handleLogout} sx={{ xs: { width: '100%', padding: 0 } }}>
                            <Text fontWeight={400} preset="titleSmall" sx={{ xs: { textAlign: 'center' } }} fontFamily="Merriweather">
                                {t('common.header.logout')}
                            </Text>
                        </CustomButton>
                    </ListItem>
                ) : (
                    <>
                        <ListItem sx={{ borderTop: '1px solid #B0BEC56E' }}>
                            <CustomLink
                                onClick={() => handleClose()}
                                to={'/login'}
                                sx={{
                                    border: '1px solid #FED8C9B2',
                                    backgroundColor: '#FED8C94D',
                                    margin: '0 auto',
                                    padding: '2px 5px',
                                    borderRadius: '3px'
                                }}
                            >
                                <Text fontWeight={400} preset="titleSmall" fontFamily="Merriweather" sx={{ xs: { textAlign: 'center' } }}>
                                    {t('common.header.login')}
                                </Text>
                            </CustomLink>
                        </ListItem>
                        <ListItem sx={{ borderTop: '1px solid #B0BEC56E', borderBottom: '1px solid #B0BEC56E' }}>
                            <CustomLink
                                onClick={() => handleClose()}
                                to={'/sign-up'}
                                sx={{
                                    border: '1px solid #FED8C9B2',
                                    backgroundColor: '#FED8C94D',
                                    margin: '0 auto',
                                    padding: '2px 5px',
                                    borderRadius: '3px'
                                }}
                            >
                                <Text fontWeight={400} preset="titleSmall" fontFamily="Merriweather" sx={{ xs: { textAlign: 'center' } }}>
                                    {t('common.header.signUp')}
                                </Text>
                            </CustomLink>
                        </ListItem>
                    </>
                )}
            </List>
        </Box>
    );
};

const MobileMenu = ({ handleLogout }: { handleLogout: () => void }) => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [anchorProfile, setAnchorProfile] = useState<null | HTMLElement>(null);
    const { isLoggedIn } = useAuth();
    const open = Boolean(anchorEl);
    const profileOpen = Boolean(anchorProfile);
    const { t } = useTranslation();

    const userInfo = useSelector((state: any) => state.user.userInfo);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorProfile(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorProfile(null);
    };

    return (
        <>
            <CustomButton onClick={handleClick}>
                <MobileMenuIcon color="#95C7D8" width="25px" />
            </CustomButton>
            <Drawer
                anchor="top"
                open={open}
                onClose={handleClose}
                PaperProps={{ sx: { backgroundColor: theme.palette.common.white, width: '100%' } }}
            >
                {isLoggedIn ? (
                    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ height: '70px' }}>
                        <Box>
                            <CustomButton onClick={handleOpenMenu}>
                                {/* {profileOpen ? <ArrowUpIcon /> : <ArrowDownIcon />} */}
                                {userInfo.profilePhoto ? (
                                    <Box>
                                        <CustomButton>
                                            <Box
                                                component="img"
                                                src={userInfo.profilePhoto}
                                                sx={{ borderRadius: '50px', width: '40px', height: '40px', cursor: 'pointer' }}
                                            />
                                        </CustomButton>
                                    </Box>
                                ) : (
                                    <PersonIcon sx={{ width: '40px', height: '40px', color: '#fff' }} />
                                )}
                            </CustomButton>
                            <Menu
                                open={profileOpen}
                                anchorEl={anchorProfile}
                                onClose={handleCloseMenu}
                                MenuListProps={{ sx: { backgroundColor: theme.palette.common.white } }}
                            >
                                <MenuItem>
                                    <CustomLink to={'/login'}>
                                        <Text fontWeight={400} preset="titleSmall">
                                            {t('common.header.memorials')}
                                        </Text>
                                    </CustomLink>
                                </MenuItem>
                            </Menu>
                        </Box>

                        <CustomButton onClick={handleClose}>
                            <CloseIcon width="25px" style={{ fill: theme.palette.grey[700] }} />
                        </CustomButton>
                    </Stack>
                ) : (
                    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ height: '86px' }}>
                        <CustomLink to="/" sx={{ marginLeft: '5%' }} onClick={handleClose}>
                            <BeSoulIcon color="#95C7D8" width="40" height="50" />
                        </CustomLink>

                        <CustomButton onClick={handleClose}>
                            <MobileMenuIcon color="#95C7D8" width="25px" />
                        </CustomButton>
                    </Stack>
                )}
                <MobileMenuList handleClose={handleClose} handleLogout={handleLogout} />
            </Drawer>
        </>
    );
};

function Header() {
    const { t } = useTranslation();
    const theme = useTheme();
    const useInfo = useSelector((state: any) => state.user.userInfo);
    const { isLoggedIn } = useAuth();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const { logout } = useAuth();
    const dispatch = useDispatch();

    const openProfileMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseProfileMenu = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        localStorage.removeItem('jwt');
        dispatch(resetUser());
        logout();
    };

    const headerNavBarElements = [
        { id: 'home', title: t('common.header.home'), link: '/', target: '' },
        { id: 'legacy', title: t('common.header.legacy'), link: 'https://legacy.besoul.app/create-a-digital-legacy', target: '' },
        { id: 'memorials', title: t('common.header.memorials'), link: '/', target: '' },
        // { id: 'soulGuide', title: t('common.header.soulGuide'), link: '#soul-guide', target: '' },
        { id: 'plans', title: t('common.header.plans'), link: 'https://legacy.besoul.app/plans-besoul', target: '' },
        { id: 'journal', title: t('common.header.journal'), link: 'https://legacy.besoul.app/start-your-video-journals', target: '_blank' },
        { id: 'blog', title: t('common.header.blog'), link: 'https://blog.besoul.app/', target: '_blank' }
    ];

    return (
        <AppBar position="relative" sx={{ zIndex: 10, backgroundColor: theme.palette.common.white, boxShadow: 'none' }}>
            <Toolbar disableGutters>
                <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
                    <CustomLink to="/" sx={{ marginLeft: '5%' }}>
                        <BeSoulIcon color="#95C7D8" width="40" height="50" />
                    </CustomLink>

                    {/* Menu en desktop */}
                    <Stack
                        direction="row"
                        justifyContent="space-evenly"
                        alignItems="center"
                        sx={(theme) => ({
                            [theme.breakpoints.up('xs')]: {
                                display: 'none'
                            },
                            [theme.breakpoints.up('md')]: {
                                display: 'flex',
                                width: '100%'
                            },
                            [theme.breakpoints.up('lg')]: {
                                width: '85%'
                            },
                            [`@media (width >= 1960px)`]: {
                                width: '50%'
                            }
                        })}
                    >
                        {headerNavBarElements.map((element) => {
                            if (element.id === 'home') {
                                return (
                                    <CustomLink
                                        key={element.id}
                                        to={element.link}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'flex-end',
                                            height: '100%',
                                            minWidth: '74px',
                                            position: 'relative'
                                        }}
                                    >
                                        <Text fontWeight={400} fontFamily="Merriweather" preset={{ md: 'titleSmall', lg: 'titleLarge' }}>
                                            {element.title}
                                        </Text>
                                        <Box
                                            position="absolute"
                                            bottom={-5}
                                            sx={{ width: '74px', height: '5px', backgroundColor: '#FDBDCC', borderRadius: '2px' }}
                                        ></Box>
                                    </CustomLink>
                                );
                            }

                            if (element.id === 'memorials') {
                                return (
                                    <MemorialModal key={element.id}>
                                        <CustomButton>
                                            <Text
                                                fontWeight={400}
                                                fontFamily="Merriweather"
                                                preset={{ md: 'titleSmall', lg: 'titleLarge' }}
                                            >
                                                {element.title}
                                            </Text>
                                        </CustomButton>
                                    </MemorialModal>
                                );
                            }

                            if (element.id === 'legacy') {
                                return (
                                    <LegacyModal key={element.id}>
                                        <CustomButton>
                                            <Text
                                                fontWeight={400}
                                                fontFamily="Merriweather"
                                                preset={{ md: 'titleSmall', lg: 'titleLarge' }}
                                            >
                                                {element.title}
                                            </Text>
                                        </CustomButton>
                                    </LegacyModal>
                                );
                            }

                            if (element.id === 'soulGuide') {
                                return (
                                    <Box sx={{ position: 'relative' }}>
                                        <Box
                                            component="span"
                                            top={{ xs: '-22px' }}
                                            right={{ xs: '-30px' }}
                                            sx={{
                                                position: 'absolute',
                                                borderRadius: '7px',
                                                padding: '2px 14px',
                                                lineHeight: '20px',
                                                background: `linear-gradient(90deg, #FED8C9, #FED8C9, #FDC1CB)`
                                            }}
                                        >
                                            <Text
                                                fontFamily="Merriweather"
                                                fontWeight={400}
                                                preset={{ xs: 'titleSmall' }}
                                                sx={{ xs: { fontSize: '14px' } }}
                                            >
                                                {t('common.header.new')}
                                            </Text>
                                        </Box>
                                        <CustomLink key={element.id} to={element.link}>
                                            <Text
                                                fontWeight={400}
                                                fontFamily="Merriweather"
                                                preset={{ md: 'titleSmall', lg: 'titleLarge' }}
                                            >
                                                {element.title}
                                            </Text>
                                        </CustomLink>
                                    </Box>
                                );
                            }
                            return (
                                <CustomLink key={element.id} to={element.link} target="_blank">
                                    <Text fontWeight={400} fontFamily="Merriweather" preset={{ md: 'titleSmall', lg: 'titleLarge' }}>
                                        {element.title}
                                    </Text>
                                </CustomLink>
                            );
                        })}

                        <Divider orientation="vertical" variant="middle" flexItem />

                        {isLoggedIn && useInfo ? (
                            <Box>
                                <CustomButton onClick={openProfileMenu}>
                                    {useInfo.profilePhoto ? (
                                        <Box
                                            component="img"
                                            src={useInfo.profilePhoto}
                                            sx={{
                                                borderRadius: '50px',
                                                width: '50px',
                                                height: '50px',
                                                cursor: 'pointer',
                                                marginRight: '5px'
                                            }}
                                        />
                                    ) : (
                                        <PersonIcon sx={{ width: '50px', height: '50px', color: '#fff', marginRight: '5px' }} />
                                    )}
                                    {open ? <ArrowUpIcon color="#45565F" /> : <ArrowDownIcon color="#45565F" />}
                                </CustomButton>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleCloseProfileMenu}
                                    MenuListProps={{ sx: { backgroundColor: theme.palette.common.white } }}
                                >
                                    <MenuItem onClick={handleCloseProfileMenu}>
                                        <CustomLink to={'/app/memorial/feed'}>
                                            <Text preset="titleLarge" fontFamily="Merriweather" fontWeight={400}>
                                                {t('common.header.memorials')}
                                            </Text>
                                        </CustomLink>
                                    </MenuItem>
                                    <MenuItem onClick={handleCloseProfileMenu}>
                                        <CustomButton onClick={handleLogout} sx={{ xs: { width: '100%', padding: 0 } }}>
                                            <Text
                                                fontWeight={400}
                                                preset="titleLarge"
                                                sx={{ xs: { textAlign: 'center' } }}
                                                fontFamily="Merriweather"
                                            >
                                                {t('common.header.logout')}
                                            </Text>
                                        </CustomButton>
                                    </MenuItem>
                                </Menu>
                            </Box>
                        ) : (
                            <>
                                <CustomLink to={'/login'}>
                                    <Text fontWeight={400} fontFamily="Merriweather" preset={{ md: 'titleSmall', lg: 'titleLarge' }}>
                                        {t('common.header.login')}
                                    </Text>
                                </CustomLink>
                                <CustomLink to={'/sign-up'}>
                                    <CustomButton sx={{ xs: { border: '1px solid #FED8C9B2', backgroundColor: '#FED8C94D' } }}>
                                        <Text fontWeight={400} fontFamily="Merriweather" preset={{ md: 'titleSmall', lg: 'titleLarge' }}>
                                            {t('common.header.signUp')}
                                        </Text>
                                    </CustomButton>
                                </CustomLink>
                            </>
                        )}

                        <Divider orientation="vertical" variant="middle" flexItem />

                        <LanguageMenu />
                    </Stack>

                    {/* Menu en mobile */}
                    <Box
                        sx={(theme) => ({
                            [theme.breakpoints.up('xs')]: {
                                display: 'inline'
                            },
                            [theme.breakpoints.up('md')]: {
                                display: 'none'
                            }
                        })}
                    >
                        <MobileMenu handleLogout={handleLogout} />
                    </Box>
                </Stack>
            </Toolbar>
        </AppBar>
    );
}
export default Header;
